import { FC, useState } from 'react'
import {
    Euro,
    LocalShipping,
    LocationOn,
    Phone,
    Restaurant,
    WatchLater,
} from '@material-ui/icons'

import {
    CustomDialog,
    ExpandButton,
    GoogleMaps,
    SPListItem,
} from '@components/common'
import { OpenTimeItem } from '@components/hero'
import { Info } from '@interfaces/info'
import { formatPrice, sortedSchedule } from '@utils/helpers'

interface Props {
    info: Info
    open: boolean
    onClose: () => void
}

const InfoDialog: FC<Props> = ({ info, open, onClose }) => {
    const [scheduleExpanded, setScheduleExpanded] = useState(false)
    const toggleExpanded = () => setScheduleExpanded(!scheduleExpanded)

    const {
        address: { label },
        schedule,
    } = info

    return (
        <CustomDialog
            maxWidth="sm"
            position="absolute"
            color="transparent"
            backIconColor="white"
            open={open}
            onClose={onClose}
        >
            {label && <GoogleMaps query={label} />}

            <div className="p1">
                <h1 className="m0 bold">{info.name}</h1>
                <p className="m0 textSecondary">{info.cusine.join(' • ')}</p>
                <p className="pre-line f16">{info.description}</p>
            </div>

            <SPListItem
                title={
                    info.openStatus.open
                        ? `Deschis pana la ${schedule[new Date().getDay()].until}`
                        : info.openStatus.next
                }
                startIcon={<WatchLater />}
                endIcon={
                    <ExpandButton
                        isExpanded={scheduleExpanded}
                        toggle={toggleExpanded}
                        border={false}
                    />
                }
                onClick={toggleExpanded}
            />
            {scheduleExpanded &&
                sortedSchedule(schedule).map((item, idx) => (
                    <OpenTimeItem
                        key={idx}
                        schedule={item}
                        expanded={scheduleExpanded}
                    />
                ))}

            <SPListItem startIcon={<Restaurant />} title={info.cusine.join(', ')} />

            <SPListItem
                title={`Livrare de la ${formatPrice(
                    Math.min(...info.delivery.zones.map((z) => z.delivery_fee)),
                )}`}
                startIcon={<LocalShipping />}
            />

            <SPListItem
                title={`Plata: ${info.payment_methods
                    .join(', ')
                    .replace('card_on_delivery', 'card la livrare')}`}
                startIcon={<Euro />}
            />

            <a
                href={`tel:${info.phoneNumber}`}
                aria-label="tel"
                style={{ borderBottom: '1px solid #000' }}
            >
                <SPListItem startIcon={<Phone />} title={info.phoneNumber} divider />
            </a>

            <a
                target="_blank"
                href={`https://maps.google.com/?q=${info.address.label}`}
                rel="noopener noreferrer"
            >
                <SPListItem
                    title={info.address.mainText}
                    startIcon={<LocationOn />}
                />
            </a>
        </CustomDialog>
    )
}

export default InfoDialog
