import { getToken, messaging } from './clientFirebase'
import { CONFIG } from './config'

export const fetchToken = async (): Promise<string | null> => {
    try {
        const token = await getToken(messaging, { vapidKey: CONFIG.VAPID_KEY })
        if (token) {
            console.log('current token for client: ', token)
            return token
        } else {
            console.log(
                'No registration token available. Request permission to generate one.',
            )
            return null
        }
    } catch (err) {
        console.log('An error occurred while retrieving token. ', err)
        throw err
    }
}
