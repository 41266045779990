import React from 'react'
import { Divider, ListItem, ListItemText } from '@material-ui/core'

import { Schedule } from '@interfaces/info'
import { dayIndexToRomanian } from '@utils/helpers'

interface Props {
    schedule: Schedule
    expanded: boolean
}

const OpenTimeItem: React.FC<Props> = ({ schedule, expanded }) => {
    const today = new Date().getDay()

    return (
        <>
            <ListItem dense disableGutters>
                <ListItemText
                    className={
                        expanded && schedule.day === today
                            ? 'between bold'
                            : 'between '
                    }
                    style={{ marginLeft: 72, marginRight: 16 }}
                    primary={dayIndexToRomanian(schedule.day)}
                    secondary={
                        !schedule.from
                            ? 'Inchis'
                            : `${schedule.from} - ${schedule.until}`
                    }
                />
            </ListItem>

            {expanded && <Divider style={{ marginLeft: 70 }} />}
        </>
    )
}

export default OpenTimeItem
