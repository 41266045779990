import { Info } from '@interfaces/info'
import { DeliveryType } from '@interfaces/order'
import { UserState } from '@interfaces/user'
import { formatPrice } from '@utils/helpers'

const EXTRA_TIME = 5
// const formattedTimeRange = (time: number) => `${time} - ${time + EXTRA_TIME} min`

export class HeroHelper {
    readonly info: Info
    readonly userState: UserState
    readonly deliveryType: DeliveryType

    readonly loading = (): boolean => this.userState.loading && this.info !== null

    constructor(info: Info, userState: UserState, deliveryType: DeliveryType) {
        this.info = info
        this.userState = userState
        this.deliveryType = deliveryType
    }

    deliveryTime() {
        const { loading, delivery } = this.userState
        if (loading) return ' '

        const restaurantDeliveryTime = this.info.delivery.time
        const userTime = Math.round(delivery.distanceMatrix.duration.value / 60)
        const roundedUserTime = Math.round(userTime / 5) * 5

        if (this.deliveryType === 'delivery') {
            return `${restaurantDeliveryTime + roundedUserTime + EXTRA_TIME * 3} min`
        } else {
            return `${restaurantDeliveryTime} min`
        }
    }

    minOrder(): string {
        const { delivery, loading, user } = this.userState
        if (this.deliveryType === 'pickup' || loading) return ' '

        const lowestDeliveryFee = Math.min(
            ...this.info.delivery.zones.map((z) => z.min_order),
        )

        return formatPrice(user ? delivery.zone.min_order : lowestDeliveryFee)
    }

    freeDelivery(): string {
        const { delivery, loading, user } = this.userState
        if (loading || this.deliveryType === 'pickup') return ''

        const lowestFreeDeliverySum = Math.min(
            ...this.info.delivery.zones.map((z) => z.free_delivery_treshold),
        )

        return (
            '>' +
            formatPrice(
                user ? delivery.zone.free_delivery_treshold : lowestFreeDeliverySum,
            )
        )
    }

    deliveryFee(): number {
        const { loading, addressError, user, delivery } = this.userState
        if (loading) return 0

        if (addressError || !user) {
            const lowestFee = Math.min(
                ...this.info?.delivery?.zones?.map((z) => z.delivery_fee),
            )

            return lowestFee
        }

        return delivery.zone.delivery_fee
    }

    distanceToRestaurant(): string {
        const { loading, addressError, user, delivery } = this.userState
        if (
            loading ||
            addressError ||
            !user ||
            !delivery.distanceMatrix.distance.text
        ) {
            return ''
        }
        return delivery.distanceMatrix.distance.text
    }

    primaryText(): string {
        return `${this.deliveryTime()} ${
            this.deliveryType === 'delivery'
                ? this.deliveryFee()
                : this.distanceToRestaurant()
        }`
    }

    secondaryText(): string {
        return this.info.cusine.join(', ')
    }
}
