import { DeliveryZone } from '@interfaces/address'
import { DeliveryType, Order, OrderStatus, PaymentType } from '@interfaces/order'

export const minOrderError = (order: Order, deliveryZone: DeliveryZone): boolean => {
    return (
        order.products.length > 0 &&
        order.delivery?.type === 'delivery' &&
        order.payment.subTotal < deliveryZone.min_order
    )
}

export const nextOrderStatus = (order: Order): OrderStatus => {
    switch (order.status) {
        case OrderStatus.PENDING:
            return OrderStatus.ACCEPTED
        case OrderStatus.ACCEPTED:
            return OrderStatus.READY

        case OrderStatus.READY: // skip ON_THE_WAY status in case it's a pickup type order
            return order.delivery.type === 'pickup'
                ? OrderStatus.DELIVERED
                : OrderStatus.ON_THE_WAY
        case OrderStatus.ON_THE_WAY:
            return OrderStatus.DELIVERED
        case OrderStatus.CANCELED:
            return OrderStatus.CANCELED
        default:
            return order.status
    }
}

export const formattedOrderStatus = (status: OrderStatus): string => {
    switch (status) {
        case OrderStatus.PENDING:
            return 'In asteptare'
        case OrderStatus.ACCEPTED:
            return 'Acceptata(e)'
        case OrderStatus.READY:
            return 'Gata de livrare'
        case OrderStatus.ON_THE_WAY:
            return 'Pe drum'
        case OrderStatus.DELIVERED:
            return 'Livrata'
        case OrderStatus.CANCELED:
            return 'Anulata'
    }
}

export const nextOrderStatusText = (order: Order): string => {
    const {
        delivery: { type },
    } = order
    switch (order.status) {
        case OrderStatus.PENDING:
            return 'Accepta comanda'
        case OrderStatus.ACCEPTED:
            return 'Gata de livrare'
        case OrderStatus.READY:
            return type === 'delivery' ? 'Trimite comanda' : 'Marcheaza ca ridicata'
        case OrderStatus.ON_THE_WAY:
            return 'Marcheaza ca livrata'
        case OrderStatus.DELIVERED:
            return type === 'delivery'
                ? 'Comanda a fost livrata'
                : 'Comanda a fost ridicata'
        case OrderStatus.CANCELED:
            return 'Anulata'
    }
}

export const orderStatusColor = (status: OrderStatus): string => {
    switch (status) {
        case OrderStatus.PENDING:
            return '#fa755a'
        case OrderStatus.ACCEPTED:
            return '#EC8F24'
        case OrderStatus.READY:
            return '#537FC1'
        case OrderStatus.ON_THE_WAY:
            return '#289D50'
        case OrderStatus.DELIVERED:
            return '#289D50'
        case OrderStatus.CANCELED:
            return '#fa755a'
        default:
            return '#000'
    }
}

export const formattedDeliveryType = (deliveryType: DeliveryType): string => {
    switch (deliveryType) {
        case 'delivery':
            return 'Livrare'
        case 'pickup':
            return 'Ridicare'
        default:
            return '-'
    }
}

export const formattedPaymentType = (paymentType: PaymentType): string => {
    switch (paymentType) {
        case 'cash':
            return 'Cash'
        case 'card_on_delivery':
            return 'Cu cardul, la livrare'
        case 'new_card':
            return 'Card nou'
        case 'saved_card':
            return 'Card salvat'
        default:
            return null
    }
}
export const formattedPaymentTypeOrder = (paymentType: PaymentType): string => {
    switch (paymentType) {
        case 'cash':
            return 'Cash, la livrare'
        case 'card_on_delivery':
            return 'Cu cardul, la livrare'
        case 'new_card':
        case 'saved_card':
            return 'Online, cu cardul'
        default:
            return 'unknown'
    }
}
