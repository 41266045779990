import dynamic from 'next/dynamic'

export const DeliveryTypeSelector = dynamic(
    () => import('@components/segmentedControl/DeliveryTypeSelector'),
    { ssr: false },
)

export { default as SEO } from '@components/common/SEO'
export { default as Hero } from '@components/hero/Hero'
export { default as TabLayout } from '@components/tabLayout/TabLayout'
export { default as MenuList } from '@components/product/menu/MenuList'
export { default as ActiveOrderBar } from '@components/order/ActiveOrderBar'
export { default as HomepageCartButton } from '@components/cart/HomepageCartButton'
