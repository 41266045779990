import { FC, useState } from 'react'
import Image from 'next/image'
import { IconButton } from '@material-ui/core'
import { InfoRounded } from '@material-ui/icons'

import { classes, InfoDialog } from '@components/hero'
import { useCheckout, useUser } from '@context'
import { useBlurData } from '@hooks'
import { Info } from '@interfaces/info'
import { CONFIG } from '@utils/config'
import { HeroHelper } from '@utils/HeroHelper'
import HeroBottomText from './HeroBottomText'

interface Props {
    info: Info | null
}

const Hero: FC<Props> = ({ info }) => {
    const heroBlurhash = useBlurData(info.images.heroBlurhash)
    const { state: userState } = useUser()
    const { state: checkoutState } = useCheckout()

    const [infoDialogOpen, setInfoDialogOpen] = useState(false)
    const toggleInfoDialog = () => setInfoDialogOpen(!infoDialogOpen)

    const loading = !info || !info.openStatus

    if (loading) {
        return <div className={classes.main__container} />
    }

    const hero = new HeroHelper(info, userState, checkoutState.delivery.type)

    return (
        <div className={classes.container}>
            <div className="relative">
                <div className={classes.half__bg} />

                <div className={classes.img__container}>
                    <div className={classes.img__sub__container}>
                        <div className={classes.img__wrapper}>
                            <IconButton
                                aria-label="info"
                                className={classes.info__button}
                                onClick={toggleInfoDialog}
                            >
                                <InfoRounded htmlColor="white" />
                            </IconButton>

                            <Image
                                className={classes.img}
                                src={info?.images?.heroUrl ?? ''}
                                alt={CONFIG.RESTAURANT_NAME}
                                layout="fill"
                                blurDataURL={heroBlurhash}
                                placeholder="blur"
                                priority={true}
                            />
                            <div className={classes.img__bg} />
                        </div>

                        <div className={classes.text__container}>
                            <div className={classes.logo__container}>
                                <Image
                                    className={classes.logo}
                                    src={info.images.logoUrl}
                                    layout="fixed"
                                    width={100}
                                    height={100}
                                    alt={`${CONFIG.RESTAURANT_NAME} logo`}
                                    placeholder="blur"
                                    blurDataURL={heroBlurhash}
                                />
                                <h4 style={{ color: 'white', margin: 0 }}>
                                    {hero.secondaryText()}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <HeroBottomText hero={hero} />

            {infoDialogOpen && (
                <InfoDialog
                    info={info}
                    open={infoDialogOpen}
                    onClose={toggleInfoDialog}
                />
            )}
        </div>
    )
}

export default Hero
