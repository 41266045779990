import React, { useEffect } from 'react'
import type { NextPage } from 'next'

import {
    ActiveOrderBar,
    DeliveryTypeSelector,
    Hero,
    HomepageCartButton,
    MenuList,
    SEO,
    TabLayout,
} from '@components/index'
import { useAlert, useStore } from '@context'
import { messaging, onMessage } from '@utils/clientFirebase'
import { CONFIG } from '@utils/config'
import { getSEODesc } from '@utils/helpers'
import { fetchToken } from '@utils/pushNotifications'
export { getStaticProps } from '../context/store/getStoreStaticProps'

const HomePage: NextPage = () => {
    const { info, restaurant } = useStore()
    const alert = useAlert()

    useEffect(() => {
        fetchToken()
        const unsub = onMessage(messaging, (payload) => {
            console.log('Message received. ', payload)
            alert.show(
                'success',
                payload.notification?.title,
                payload.notification?.body,
            )
        })

        return () => unsub()
    }, [])

    return (
        <>
            <SEO
                title={CONFIG.RESTAURANT_NAME}
                description={getSEODesc(info, restaurant.menus)}
                url={info.url}
                imageUrl={info.images.heroUrl}
            />

            <Hero info={info} />
            <ActiveOrderBar />
            <DeliveryTypeSelector paddingTop />
            <TabLayout menus={restaurant.menus} />
            <MenuList menus={restaurant.menus} toppings={restaurant.toppings} />
            <HomepageCartButton />
        </>
    )
}

export default HomePage
