import React from 'react'
import { ListItemText } from '@material-ui/core'

import { classes } from '@components/hero'
import { formatPrice } from '@utils/helpers'
import { HeroHelper } from '@utils/HeroHelper'

interface Props {
    hero: HeroHelper
}
const HeroBottomText: React.FC<Props> = ({ hero }) => {
    const { userState, deliveryType } = hero

    if (userState.loading) {
        return <div className={classes.bottom__container__loading} />
    }

    return (
        <div
            style={{
                backgroundColor: '#f3f4f6',
                padding: '0.5rem 0',
            }}
        >
            <div className={classes.bottom__container}>
                <ListItemText
                    className={classes.text}
                    primary={
                        deliveryType === 'delivery' ? 'Ajunge in' : 'Ridicare in'
                    }
                    secondary={hero.deliveryTime()}
                />

                {deliveryType === 'delivery' ? (
                    <>
                        <ListItemText
                            className={classes.text}
                            primary={
                                userState.addressError || !userState.user
                                    ? 'Livrare de la'
                                    : 'Livrare'
                            }
                            secondary={formatPrice(hero.deliveryFee())}
                        />

                        {hero.deliveryFee() === 0 ? (
                            <ListItemText
                                className={classes.text}
                                primary="Comanda min."
                                secondary={hero.minOrder()}
                            />
                        ) : (
                            <ListItemText
                                className={classes.text}
                                primary="Liv. gratis"
                                secondary={hero.freeDelivery()}
                            />
                        )}
                    </>
                ) : (
                    <>
                        {/* <span className={classes.text__item}>
                        {hero.distanceToRestaurant() && 'Distanta'}
                        <p>{hero.distanceToRestaurant()}</p>
                    </span> */}

                        {hero.distanceToRestaurant() && (
                            <ListItemText
                                className={classes.text}
                                primary="Distanta"
                                secondary={hero.distanceToRestaurant()}
                            />
                        )}

                        {hero.info.delivery?.types?.includes('pickup') && (
                            <ListItemText
                                className={classes.text}
                                primary="Adresa"
                                secondary={hero.info.address.mainText
                                    .replace('Strada', 'Str.')
                                    .substring(0, 30)}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

export default HeroBottomText
