import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import {
    collection,
    limit,
    onSnapshot,
    orderBy,
    query,
    where,
} from 'firebase/firestore'
import { Container, Typography } from '@material-ui/core'

import { useUser } from '@context'
import { activeOrderStatus, Order } from '@interfaces/order'
import { clientDb } from '@utils/clientFirebase'
import { CONFIG } from '@utils/config'
import { formattedOrderStatus } from '@utils/orderHelpers'

const ActiveOrderBar = () => {
    const { state } = useUser()
    const [order, setOrder] = useState<Order>(null)

    useEffect(() => {
        if (!state.user) return

        const unsubscribe = onSnapshot(
            query(
                collection(clientDb, `restaurants/${CONFIG.RESTAURANT_ID}/orders`),
                where('uid', '==', state.user?.uid),
                where('status', 'in', activeOrderStatus),
                orderBy('createdAt', 'desc'),
                limit(1),
            ),
            (snapshot) => {
                if (snapshot.empty) {
                    setOrder(null)
                } else {
                    const orders = snapshot.docs.map((doc) => doc.data()) as Order[]
                    setOrder(orders[0])
                }
            },
            (error) => {
                console.error(error.message)
            },
        )

        return () => unsubscribe()
    }, [state.user])

    if (state.loading || !state.user || !order) return null

    return (
        <div
            style={{
                backgroundColor: '#FBF2D5',
            }}
        >
            <Container className="pv05">
                <Link href={`orders/${order.id}`} passHref>
                    <a className="between" style={{ color: '#877d51' }}>
                        <Typography>
                            Comanda <b>#{order.trackingId}</b>
                        </Typography>

                        <Typography variant="button">
                            Status:{' '}
                            <b>{formattedOrderStatus(order.status).toLowerCase()}</b>{' '}
                        </Typography>
                    </a>
                </Link>
            </Container>
        </div>
    )
}

export default ActiveOrderBar
